/* eslint no-invalid-this: 0, complexity:[2, 9] */
import { qs, qsa, $on, $parent, $delegate } from "./helpers";

const _taskTypeChange = (taskType) => {
    if (taskType === 'purch') {
        qs('#h').style.display = 'none';
        qs('#v').style.display = 'inline';
    } else {
        qs('#v').style.display = 'none';
        qs('#h').style.display = 'inline';
    }
}

const _addOptions = (selector, data) => {
    const list = qs(selector)
    for (let k in data) {
        const opt = document.createElement("option");
        opt.setAttribute("id", k);
        opt.appendChild(document.createTextNode(data[k]));        
        list.appendChild(opt);
    };    
}
const ENTER_KEY = 13;
const ESCAPE_KEY = 27;

export default class View {
    constructor(template) {
        this.template = template;
        this.$prev = qs(".prev");
        this.$when = qs(".when");
        this.$next = qs(".next");
        // this.$taskType = qs(".task-type");
        this.$categories = qs(".category");
        this.$addTask = qs(".common-what"); // naming ? could also use `qs('#what')`
        this.$addLocation = qs(".common-where"); // naming ? could also use `qs('#where')`
        this.$workItem = qs(".capture-task"); // also naming :-/
        this.$workItemList = qs(".capture-items"); // also naming :-/
        this.$amount = qs("input.amount");
        this.$done = qs(".done");
        
        this.$title = qs("h1");
        this.lastClicked = new Date().getTime();

        this.$today = qs("#today");
        this.$status = qs(".status");
        this.$config = qs(".config");

        this.render = this.render.bind(this);
        this.bindCallback = this.bindCallback.bind(this);
    }

    _incrementDate (current, next) {
        this.$when.value = next;
        if (next === current) {
            this.$next.classList.add('disabled');
        } else {
            this.$next.classList.remove('disabled');
        }
    }

    _addTaskToWorkItem (task) {
        this.$workItem.value += ` ${task} `;
        this.$workItem.focus();
        this.$addTask.selectedIndex = 0;            
    }

    _addLocationToWorkItem (location) {
        this.$workItem.value += ` [${location}] `;
        this.$workItem.focus()       
        this.$addLocation.selectedIndex = 0;
    }
    _amountUpdate(amount) {
        // no-op ???        
    }
    _workItemUpdate(content) {
        // no-op ???        
    }
    _clearInputs() {
        this.$workItem.value = "";
        this.$amount.value = 0;
        this.$done.classList.add("disabled");
        this.$done.disabled = true;
    }
    _authUpdate(state) {
        const _stateMap = {"open": "gray", "auth": "red", "lock": "green", "clear": "green"};
        this.$title.style.color = _stateMap[state];
        if (state === "clear") {
            this.$title.style.color = "black";
            const authCode = window.prompt("Enter Authentication Code");
            if (authCode) {
                localStorage.setItem("authCode", authCode); 
            }
        }
    }
    // eslint-disable-next-line complexity
    render(viewCmd, parameter) {
        switch (viewCmd) {
            case "incrementDate":
                this._incrementDate(parameter.current, parameter.next);
                break;
            case "dateUpdate":
                this.$today.innerHTML = parameter.dateStamp;
                this.$workItemList.replaceChildren();
                this.$workItemList.innerHTML = this.template.showWorkItems(parameter.list);
                this.$status.innerHTML = this.template.showStatus(parameter.stat);
                break;
            case "taskTypeChange":
                _taskTypeChange(parameter.taskType);
                break;
            case "addTaskToWorkItem":
                this._addTaskToWorkItem(parameter.task);
                break;
            case "addLocationToWorkItem":
                this._addLocationToWorkItem(parameter.location);
                break;
            case "enableDone":
                this.$done.classList.remove('disabled');
                this.$done.disabled = false;
                break;
            case "amountUpdate":
                this._amountUpdate(parameter.amount);
                break;
            case "workItemUpdate":
                this._workItemUpdate(parameter.content);
                break;
            case "displayWorkItems":
                this._clearInputs();
                this.$workItemList.innerHTML = this.template.showWorkItems(parameter.list);                
                this.$status.innerHTML = this.template.showStatus(parameter.stat);
                break;
            case "showConfig":
                alert("config dialog goes here");
                break;
            case "authUpdate":
                this._authUpdate(parameter.state);
                break;
            case "locations":
                _addOptions("#where", parameter.data);
                break;
            case "workItems":
                _addOptions("#what", parameter.data);
                break;
            case "startDate":
                this.$when.value = parameter.date;
                break
            }
    }

    bindCallback(event, handler) {
        switch (event) {
            case "incrementDate":
                $on(this.$prev, "click", (e) => { 
                    if (e.target.classList.contains('disabled'))
                        return;       
                    handler(`${this.$when.value} 00:00:01.234`, -1); 
                });
                $on(this.$next, "click", (e) => { 
                    if (e.target.classList.contains('disabled'))
                        return;       
                    handler(`${this.$when.value} 00:00:01.234`, +1); 
                });
                break;
            case "dateUpdate":
                $on(this.$when, "change", (e) => handler(this.$when.value));
                $on(this.$when, "blur", (e) => handler(this.$when.value)); // ??
                break;
            case "taskTypeChange":
                $delegate(this.$categories, 'input[type="radio"]', 'click', (e) => {
                    if (e.target.checked) {
                        // this all seems rather clumsy... is there a better way ?
                        qsa("label", this.$categories).forEach((element) => {
                            element.classList.remove('btn-info');
                            element.classList.remove('btn-secondary');
                            element.classList.remove('btn-success');
                        });
                        let info = this.template.typeInfo({ Type: e.target.value });
                        qs("label", $parent(e.target, 'div')).classList.add("btn", `btn-${info.color}`);
                        handler(e.target.value);
                    } 
                });
                break;
            case "addTaskToWorkItem":
                $on(this.$addTask, "change", (e) => handler(this.$addTask.value));
                break;
            case "addLocationToWorkItem":
                $on(this.$addLocation, "change", (e) => handler(this.$addLocation.value));
                break;
            case "workItemUpdate": 
                $on(this.$workItem, "change", (e) => handler(this.$workItem.value));
                $on(this.$workItem, "blur", (e) => handler(this.$workItem.value)); //??
                break;
            case "amountUpdate":
                $on(this.$amount, "change", (e) => handler(this.$amount.value));
                $on(this.$amount, "blur", (e) => handler(this.$amount.value)); //??
                $on(this.$amount, "keypress", (e) => {
                    if (e.keyCode === ENTER_KEY) {
                        e.target.blur();
                    }
                });
                break;
            case "workItemSave":
                $on(this.$done, "click", (e) => handler(this.$when.value));
                break;
            case "workItemDelete":
                $delegate(this.$workItemList, ".remove", "click", (e) => {                     
                    let element = e.target.parentNode;
                    let item = {
                        date : this.$when.value,
                        value : qs('span', element).textContent.trim(),
                        type : qs('span', element).dataset.type,
                        content : qs('label', element).textContent.trim()
                    }
                    handler(item); 
                });
                break;
            case "authUpdate":
                $on(this.$title, "click", (e) => {
                    let thisClick = new Date().getTime();
                    handler(thisClick - this.lastClicked);
                    this.lastClicked = thisClick;
                });
                break;
            case "showConfig":
                $delegate(this.$status, ".config", (e) => { handler(e.target); });
                break;
            case "clearStorage":
                $on(this.$today, "dblclick", (e) => { handler(e.target); });
                break;
            }
    }
}
