const defaultType = 'maint';

const defaultLocations = {
    1: "Unit 1",
    2: "Unit 2",
    3: "Unit 3",
    4: "Unit 4",
    5: "Unit 5",
    6: "Unit 6",
    7: "Unit 7",
    8: "Unit 8",
    9: "Unit 9",
    10: "Unit 10",
    12: "Parking",
    14: "Laundry"
};
const defaultWorkItems = {
    1: "Daily Inspection",
    2: "Snow Removal - Richard",
    3: "Snow Removal - Nina",
    4: "Purchase snow melt",
    5: "Clean and maintain laundry",
};

const stringToHash = function (string) { 
    return string.split('').reduce((hash, char) => { 
        return char.charCodeAt(0) + (hash << 6) + (hash << 16) - hash; 
    }, 0);
}

class Model {
    constructor(storage) {
        this.count = 0;
        this.storage = storage;
        this.capture = {
            Type: defaultType,
            Trades: "0.0",
            Task: "",
            Locations: [],
            Date: "",
            Maint: "0.0",
            Purchase: "0.0"
        };
    }
    locations(callback) {
        callback(defaultLocations);
    }
    workItems(callback) {
        callback(defaultWorkItems); 
    }
    nextDate(current, increment, callback) {
        const date = this._calculateDate(current, increment);
        callback(date);
    }
    setTaskType(taskType, callback) {
        this.capture.Type = taskType;
        callback(taskType);
    }
    setAmount(amount, callback) {
        switch (this.capture.Type) {
            case 'maint':
                this.capture.Maint = parseFloat(amount);
                break;                
            case 'purch':
                this.capture.Purchase = parseFloat(amount);
                break;                
            case 'trade':
                this.capture.Trades = parseFloat(amount);
                break;
            default:
                console.log("Error : ", capture.type)
                break;
        }
        callback(amount);
    }
    updateAuth(interval, callback) {
        const _lockStates = ["open", "auth", "lock", "clear"];
        if (interval > 420 && interval < 999) {
            this.count += 1;
            const state = _lockStates[this.count-1];            
            if (state === "clear")
                this.count = 0;
            callback(state)            
        } else { this.count = 0; }
    }
    updateWorkItem(content, callback) {
        this.capture.Task = content;
        callback(content);
    }
    appendLocation(value, callback) {
        this.capture.Locations.push(value)        
        callback(value);
    }
    read(date, callback) {
        this.storage.load(date, (data) => { 
            if (this._shouldUpload(data.summary.updated)) {
                this.storage.upload();
            }
            const stat = this.storage.summary()
            const items = this.storage.findAll((e,i,a) => { return e.Date === date }) ?? [];
            callback(items, stat)
        });
    }
    findWorkItem(filter) {
        return this.storage.findAll(filter)
    }

    removeWorkItem() {}
    saveWorkItem(date, callback) {
        this.capture.Date = date;
        try {
            this._ensureUnique(date)
            this.storage.save(this.capture, (update) => { 
                const stat = this.storage.summary()
                const items = this.storage.findAll((e,i,a) => { return e.Date === date }) ?? [];
                callback(update, items, stat);
            });
        } catch (error) {
            alert(error)
            throw error;
        }
    }
    clearStorage() {
        if (window.confirm("Are you sure you want to clear all workitems?\n")) {
            this.storage.clear();
        }
    }
    validate(callback) {
        callback(this._isValidTask());
    }

    _calculateDate(current, direction) {
        // assumes direction is either 1 or -1
        var value = new Date(current);
        value.setDate(new Date(current).getDate() + direction);
        const m = (value.getMonth() + 1).toString().padStart(2, '0');
        const d = value.getDate().toString().padStart(2, '0');
        return `${value.getFullYear()}-${m}-${d}`;
    }
    _ensureUnique(date) {
        let c = this.capture;
        let filter = (e,i,a) => { 
            return e.Type === c.Type 
            && parseFloat(e.Maint) === parseFloat(c.Maint) 
            && parseFloat(e.Purchase) === parseFloat(c.Purchase) 
            && parseFloat(e.Trades) === parseFloat(c.Trades) 
            && e.Date === date
            && e.Task.trim() === c.Task.trim() 
        };        
        const items = this.findWorkItem(filter) ?? [];
        if (items.length){
            console.log(c, items.toString());
            throw Error("duplicates found")
        }
        return;
    }
    _isValidTask() {
        return (this.capture.Type !== null) 
            && (parseFloat(this.capture.Maint)+parseFloat(this.capture.Purchase)+parseFloat(this.capture.Trades)) > 0
            && this.capture.Task.length > 7;
    }
    _shouldUpload(timestamp) {
        const lastUpdated = new Date(timestamp);
        return (((new Date()-lastUpdated)/1000)/60) > 10; // minutes
        //return (((new Date()-lastUpdated)/1000)/60/60) > 12; // hours
    }
}

export default Model;
