import View from "./view";
import Controller from "./controller";
import Model from "./model";
import Store from "./store";
import Template from "./template";

// Import all of Bootstrap's JS (if needed)
//import * as bootstrap from 'bootstrap'

import './scss/styles.scss'
import "./app.css";


let workItems;
const onHashChange = () => {
    workItems.controller.setView(document.location.hash);
};

const onLoad = () => {
    // TODO construct name from current date?
    workItems = new WorkItems("2024_09"); // ??
    onHashChange(); // ??
};

const APP_VERSION = "1.0.0.3"

function WorkItems(name) {
    this.storage = new Store(name); // should the store pick up the current date ??
    this.model = new Model(this.storage);
    this.template = new Template(APP_VERSION);
    this.view = new View(this.template);
    this.controller = new Controller(this.model, this.view);
}

/* HOT MODULE SPECIFIC */
if (module.hot) {
    module.hot.accept(function (err) {});
    if (document.readyState === "complete")
        onLoad();
}

window.addEventListener("load", onLoad);
window.addEventListener("hashchange", onHashChange);
