let uniqueID = 1;
/* HOT MODULE SPECIFIC
 * Since hot reload blows away class instances, storage object is
 * moved outside of the class.
 */
let emptyData = {
    items:  [],
    summary: {
        updated: null,
        total_hours: 0,
    }
}
export class Store {
    constructor(name, callback) {
        this.loadRemote = false;
        this._load(name, callback)
    }
    _load(name, callback) {
        this._dbName = name;
        let localCache = localStorage.getItem(name);
        if (!localCache && this.loadRemote) {
            fetch("/raw/"+name)
            .then(response => response.json())
            .then(remoteData => { 
                console.log(remoteData); 
                const data = remoteData ? remoteData : emptyData
                localStorage.setItem(name, JSON.stringify(data));
                if (callback)
                    callback(data);
            });
        }
        if(!localCache){
            localStorage.setItem(name, JSON.stringify(emptyData));
            localCache = localStorage.getItem(name);
        }
        if (callback)
            callback(JSON.parse(localCache));
    }
    summary() {
        const data = JSON.parse(localStorage.getItem(this._dbName));
        return {
            total: data.summary.total_hours,
            update: data.summary.updated,
            count: data.items.length,
        }
    }
    load(date, callback) {
        var name = date.split("-", 2).join("_")
        this._load(name, callback);
    }
    upload(callback) {
        // perhaps this can take success and error callbacks???
        const data = JSON.parse(localStorage.getItem(this._dbName));
        if (data.items.length && data.summary.total_hours) {
            const token = localStorage.getItem("authCode");
            data.summary.updated = new Date().toISOString();
            fetch(`/${this._dbName}`, {
                mode: 'no-cors', // this will need to change!!!
                method: "POST",
                headers: { "Content-Type": "application/json", "Authorization": token},
                body: JSON.stringify(data),
            })
            .then(response => { 
                localStorage.setItem(this._dbName, JSON.stringify(data));
                console.log(response); // pretty sure we can do better here...
            }) 
            .catch(error => { console.log(error); });
        }
    }
    clear() { localStorage.removeItem(this._dbName); }
    save(update, callback) {           
        const data = JSON.parse(localStorage.getItem(this._dbName));
        // not sure if the store should have this knowledge of the data structure 
        // perhaps this hours total can be a parameter
        const total = (parseFloat(update.Maint)+parseFloat(update.Trades)) 
        data.summary.total_hours += total
        data.items.push(update);
        localStorage.setItem(this._dbName, JSON.stringify(data));
        if (callback)
            callback(update);
    }

    findAll(callback) {
        if (!callback)
            return;

        return JSON.parse(localStorage.getItem(this._dbName)).items.filter(callback);
    }
}

export default Store;
