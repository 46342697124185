const reference = (function() {
    const now = new Date();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    return {
        date : `${now.getFullYear()}-${month}-${day}`,
        time: `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}.${now.getMilliseconds()}`,
        string: now.toDateString(),
        key: `${now.getFullYear()}_${month}`,
    };
})();

class Controller {
    constructor(model, view) {
        this.model = model;
        this.view = view;

        this.view.bindCallback("incrementDate", (current, direction) => this.incrementDate(current, direction));
        this.view.bindCallback("dateUpdate", (date) => this.dateUpdate(date));
        this.view.bindCallback("taskTypeChange", (taskType) => this.taskTypeChange(taskType));
        this.view.bindCallback("addTaskToWorkItem", (task) => this.addTaskToWorkItem(task));
        this.view.bindCallback("addLocationToWorkItem", (location) => this.addLocationToWorkItem(location));
        this.view.bindCallback("workItemUpdate", (content) => this.workItemUpdate(content));
        this.view.bindCallback("amountUpdate", (amount) => this.amountUpdate(amount));
        this.view.bindCallback("workItemSave", (date) => this.workItemSave(date));
        this.view.bindCallback("workItemDelete", (item) => this.workItemDelete(item));
        this.view.bindCallback("authUpdate",  (interval) => this.authUpdate(interval));
        this.view.bindCallback("clearStorage",  () => this.model.clearStorage());
        this.view.bindCallback("showConfig",  () => this.view.render('showConfig', {}));

        this.model.locations((data) => { this.view.render('locations', { data }); });
        this.model.workItems((data) => { this.view.render('workItems', { data }); });
    }
    incrementDate(current, direction) {
        this.model.nextDate(current, direction, (date) =>{
            this.view.render('incrementDate', { current: reference.date, next: date } );
            this.dateUpdate(date);
        });
    }
    dateUpdate(date) {
        this.model.read(date, (list, stat) => {
            const dateStamp = new Date(`${date} ${reference.time}`).toDateString();
            this.view.render("dateUpdate", { dateStamp, list, stat }); 
        });
    }
    taskTypeChange(taskType) {
        this.model.setTaskType(taskType, () => {
            this.view.render("taskTypeChange", { taskType });
        });
    }
    addTaskToWorkItem(task) {
        this.view.render("addTaskToWorkItem", { task });
        this._enableDone();
    }
    addLocationToWorkItem(location) {
        this.model.appendLocation(location, () => {
            this.view.render("addLocationToWorkItem", { location });
            this._enableDone();
        });
    }
    workItemUpdate(content) {
        this.model.updateWorkItem(content, () => {
            this.view.render("workItemUpdate", { content });
            this._enableDone();
        });
    }    
    amountUpdate(amount) {
        this.model.setAmount(amount, () => {
            this.view.render("amountUpdate", { amount });
            this._enableDone();
        });
    }
    workItemSave(date) {
        this.model.saveWorkItem(date, (item, list, stat) => {
            this.view.render("displayWorkItems", { item, list, stat });
        });
    }
    workItemDelete(item) {
        let workItem = this.model.findWorkItem((e,i,a) => {
            return e.Date === item.date
            && e.Task.trim() === item.content
            && e.Type === item.type
        });

        if (window.confirm(`Are you sure you want to delete this work item ?\n${item.value} ${item.content}`)) {
            this.model.removeWorkItem(workItem);
            console.log('item delete', workItem);
        }
    }
    authUpdate(interval) {
        this.model.updateAuth(interval, (state) => {
            this.view.render("authUpdate", { state })
        });   
    }    

    _enableDone() { 
        this.model.validate((valid) => { 
            if (valid) { this.view.render("enableDone"); }
        });
    }
    
    setView(hash) {
        const route = hash.split("/")[1];
        const page = route || "";
        const date = reference.date
        this.view.render("startDate", { date });
        this.dateUpdate(date);        
    }
}

export default Controller;
